import React, { Component } from 'react'
import styled from '@emotion/styled'
import Container, { Row } from '../UI/Grid'
import { variableHeadingTag, getSize } from '../UI/Headings'
import Text from '../UI/Text'
import { getGlobal, setGlobal } from '../../utility/functions'

/*==============================================================================
  # Styles
==============================================================================*/


const Inner = styled('div')`
  max-width: 800px;
  padding: 0px 15px;
  margin: 0 auto;
  text-align: left;
`

const StyledText = styled(Text)`
  margin: 30px auto 0px;
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionText extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }
  
  render () {

    let { id, isH1, title, title_size, text, margin } = this.props

    const Title = variableHeadingTag(getSize(title_size))
    const size = isH1 ? "h1" : "h2"

    return (
      <Container id={id} ref={this.scrollRef} marginTop={margin}>
        <Row>
          <Inner>

            <Title size={size}>{title}</Title>
            <StyledText content={text} />

          </Inner>
        </Row>
      </Container>
    )
  }
}

export default SectionText