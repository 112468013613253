import React, { Component } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { ButtonLink } from './Button'
import { SubHeading } from './Headings'
import Text from './Text'
import { Row } from './Grid'

/*==============================================================================
  # Styles
==============================================================================*/

export const TextCardContainer = styled(Row)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Card = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  min-height: 450px;
  padding: ${({imageCardStyle}) => imageCardStyle ? '20px' : '50px 30px 20px'};
  margin: 15px;
  text-align: center;
  background-color: ${({theme}) => theme.colors.bg};
  box-shadow: ${({theme}) => theme.styles.boxshadow};

  & > * {
    margin-left: auto;
    margin-right: auto;
  }
`

const Icon = styled(Img)`
  margin-bottom: 30px;
`

const Image = styled(Img)`
  margin-bottom: 15px;
`

const StyledText = styled(Text)`
  margin-top: 15px;
  margin-bottom: 30px;
  transition: all 250ms ${({theme}) => theme.easings.secondary};
  ${({theme}) => theme.fontSizes.regular}
`

const StyledLink = styled(ButtonLink)`
  display: inline-block;
  margin-top: auto;
  margin-bottom: 30px;
`



/*==============================================================================
  # Component
==============================================================================*/


class TextCard extends Component {

  render () {

    let { title, text, link, icon, image, className } = this.props
    const imageCardStyle = !!image

    return (
      <Card className={className} imageCardStyle={imageCardStyle}>
        {imageCardStyle && image.localFile && image.localFile.childImageSharp &&
          <Image
            style={{
              width: '100%',
              height: 0,
              paddingBottom: '50%'
            }}
            fluid={image.localFile.childImageSharp.fluid}
            alt={image.alt_text}
          />
        }
        {!imageCardStyle && icon && icon.localFile && icon.localFile.childImageSharp &&
          <Icon
            fixed={icon.localFile.childImageSharp.fixed}
            alt={icon.alt_text}
          />
        }
        <SubHeading>{title}</SubHeading>
        <StyledText content={text} />
        {link && <StyledLink to={link.to}>{link.title}</StyledLink>}
      </Card>
    )
  }
}

export default TextCard