import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby'
import GoogleMapReact from 'google-map-react'

import MarkerIcon from '../../img/marker-icon.svg'

/*==============================================================================
  # Styles
==============================================================================*/

const MapContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.1);
`

const MarkerStyle = styled('span')`
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -100%);

  svg {
    width: 100%;
    height: 100%;
    fill: ${({theme}) => theme.colors.primary};
  }
`

const TextStyle = styled('span')`
  position: absolute;
  bottom: calc( 100% + 5px);
  left: 50%;
  display: block;
  width: 150px;
  padding: 10px;
  border-radius: 10px;
  color: ${({theme}) => theme.colors.black};
  background-color: ${({theme}) => theme.colors.white};
  font-size: 10px;
  visibility: visible;
  box-shadow: ${({theme}) => theme.styles.boxshadow};
  transition: all 250ms ${({theme}) => theme.easings.secondary};
  transform: translate(-50%, 0%);

  ${({hover}) => !hover && css`
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 10%);
  `} 

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-top-color: ${({theme}) => theme.colors.white};
    border-width: 5px;
    margin-left: -5px;
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class Marker extends Component {

  state = {
    hover: false
  }

  onMouseEnter = () => {
    this.setState({
      hover: true
    })
  }

  onMouseLeave = () => {
    this.setState({
      hover: false
    })
  }

  render() {

    const { hover } = this.state
    const { text } = this.props

    return (
      <MarkerStyle
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        {...this.props}
      >
        <MarkerIcon />
        {text && <TextStyle hover={hover}>{text}</TextStyle>}
      </MarkerStyle>
    )
  }
}

class SimpleMap extends Component {

  static defaultProps = {
    lat: 59.33,
    lng: 18.06,
    zoom: 11
  }

  render() {
    return (
      <StaticQuery 
        query={graphql`
          query MapKeyQuery {
            ...MapKey
          }
        `}
        render={data => {

          const key = data.allWordpressAcfOptions.edges[0].node.options.google_maps_key
          let { lat, lng, address, zoom } = this.props

          lat = parseFloat(lat)
          lng = parseFloat(lng)

          return (
            <MapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{ key: key }}
                defaultCenter={{
                  lat: lat,
                  lng: lng
                }}
                defaultZoom={zoom}
              >
                <Marker
                  lat={lat}
                  lng={lng}
                  text={address}
                />
              </GoogleMapReact>
            </MapContainer>
          )
        }}
      />
    )
  }
}

export default SimpleMap;