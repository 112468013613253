import React, { Component } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Container, { Row, Col } from '../UI/Grid'
import { Mega } from '../UI/Headings'
import Text, { LargeText } from '../UI/Text'
import LinkContainer from '../UI/LinkContainer'
import { getGlobal, setGlobal } from '../../utility/functions'

/*==============================================================================
  # Styles
==============================================================================*/

const FullContainer = styled(Container)`
  min-height: 60vh;
  padding: 60px 0px;
  text-align: center;
  color: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.above.md} {
    padding: 100px 0px;
  }

  ${({theme}) => theme.above.lg} {
    padding: 150px 0px;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 1;
  }
`

const ContentContainer = styled(Container)`
  justify-content: center;
  z-index: 2;
`

const StyledText = styled(Text)`
  margin: 15px auto;
  color: ${({theme}) => theme.colors.white};
`

const StyledLargeText = styled(LargeText)`
  margin: 15px auto;
  color: ${({theme}) => theme.colors.white};
`

const Links = styled(LinkContainer)`
  margin-top: 30px;

  ${({theme}) => theme.above.md} {
    margin-top: 60px;
  }

  ${({theme}) => theme.above.lg} {
    margin-top: 90px;
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionIntroBtns extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }
  
  render () {

    let { id, isH1, margin, image, title, text, largetext, linktext, links } = this.props

    const size = isH1 ? "h1" : "h2"
    const TextComponent = largetext ? StyledLargeText : StyledText


    return (
      <FullContainer id={id} ref={this.scrollRef} fullWidth marginTop={margin}>

        {image && image.localFile &&
          <Img 
            fluid={image.localFile.childImageSharp.fluid}
            alt={image.alt_text}
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }}
          />
        }

        <ContentContainer>
          <Row>
            <Col col={12}>
              <Mega size={size}>{title}</Mega>
              <TextComponent content={text} />
              <Links linkText={linktext} links={links} />
            </Col>
          </Row>
        </ContentContainer>
      </FullContainer>
    )
  }
}

export default SectionIntroBtns