import React, { Component } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Heading } from './Headings'
import Text from './Text'
import { Row, Col } from './Grid'

/*==============================================================================
  # Styles
==============================================================================*/

export const CoworkerCardContainer = styled(Row)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: -15px;

  ${({theme}) => theme.above.md} {
    margin-bottom: -30px;
    margin-left: -30px;
    margin-right: -30px;
  }
`

const Card = styled(Col)`
  width: 390px;
  min-width: 340px;
  max-width: 360px;
  padding: 15px;
  margin-bottom: 15px;

  ${({theme}) => theme.above.md} {
    max-width: 390px;
    padding: 30px;
    margin-bottom: 30px;
  }
`

const ProfileImg = styled(Img)`
  box-shadow: ${({theme}) => theme.styles.boxshadow};
`

const Name = styled(Heading)`
  text-transform: none;
`

const Info = styled(Text)`
  min-height: 25px;
  margin-bottom: 2px;

  a.mail,
  a.phone {
    color: ${({theme}) => theme.colors.blue};
  }
`

const Title = styled(Info)`
  margin-top: 2px;
  margin-bottom: 20px;
`


/*==============================================================================
  # Component
==============================================================================*/


class TextCard extends Component {

  render () {

    let { name, title, phone, mail, image } = this.props

    return (
      <Card>
        {image && image.localFile &&
          <ProfileImg 
            fluid={image.localFile.childImageSharp.fluid}
            alt={image.alt_text}
            style={{
              width: '100%',
              height: 0,
              paddingBottom: '83.33%',
              marginBottom: 15
            }}
          />
        }
        <Name>{name}</Name>
        <Title content={title} />
        {phone && <Info content={phone}/>}
        {mail && <Info content={mail}/>}
      </Card>
    )
  }
}

export default TextCard