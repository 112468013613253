import React from 'react'
import styled from '@emotion/styled'
import { ButtonLink } from '../UI/Button'

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  margin: -5px;
`

const Text = styled('p')`
  margin: 5px;
`

const Button = styled(ButtonLink)`
  margin: 5px;
`


/*==============================================================================
  # Component
==============================================================================*/

const LinkContainer = ({links, linkText, ...rest}) => {

  return (
    <Wrapper {...rest}>
      <Text>{linkText}</Text>
      {links && links.map((ele, i) => <Button key={i} secondary={ele.colored ? 0 : 1} to={ele.link.url} {...ele.link}>{ele.link.title}</Button>)}
    </Wrapper>
  )
}

export default LinkContainer