import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from "gatsby-image"
import Container, { Row, Col } from '../UI/Grid'
import { variableHeadingTag, getSize } from '../UI/Headings'
import Text, { LargeText } from '../UI/Text'
import LinkContainer from '../UI/LinkContainer'
import { getGlobal, setGlobal } from '../../utility/functions'

/*==============================================================================
  # Styles
==============================================================================*/

const StyledRow = styled(Row)`
  position: relative;
  color: ${({theme, background}) => background === 'blue' ? theme.colors.white : theme.colors.black};

  p {
    color: ${({theme, background}) => background === 'blue' ? theme.colors.white : theme.colors.black};
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    background-color: ${({theme, background}) => background !== 'none' ? theme.colors[background] : 'transparent'};
    z-index: 1;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  ${({theme}) => theme.below.md} {
    text-align: center;
  }

  ${({theme}) => theme.above.md} {
    order: ${({order}) => order};
  }
`

const ContentCol = styled(StyledCol)`
  padding: ${({extraPadding}) => extraPadding ? '30px' : '30px 15px 0px'};

  ${({theme}) => theme.above.md} {
    padding-top: ${({extraPadding}) => extraPadding ? '60px' : '0px'};
    padding-bottom: ${({extraPadding}) => extraPadding ? '60px' : '0px'};
    padding-left: 15px;
    padding-right: 15px;

    ${({order}) => order === 1 ? css`
      padding-right: 30px;
    ` : css`
      padding-left: 30px;
    `}
  }

  ${({theme}) => theme.above.lg} {
    padding-top: ${({extraPadding}) => extraPadding ? '100px' : '0px'};
    padding-bottom: ${({extraPadding}) => extraPadding ? '100px' : '0px'};
  }

  ${({theme}) => theme.above.xl} {
    ${({order}) => order === 1 ? css`
      padding-right: 60px;
    ` : css`
      padding-left: 60px;
    `}
  }
`

const ImgContainer = styled('div')`
  position: relative;
  width: 100%;
  z-index: 2;

  ${({theme, imageType, extraPadding}) => imageType === 'image' ? css`

    ${theme.above.md} {
      padding-top: ${extraPadding ? '60px' : '0px'};
      padding-bottom: ${extraPadding ? '60px' : '0px'};
    }

    ${theme.above.lg} {
      padding-top: ${extraPadding ? '100px' : '0px'};
      padding-bottom: ${extraPadding ? '100px' : '0px'};
    }

  ` : css`
    ${theme.above.md} {
      height: 100%;
      min-height: 550px;
    }
  `}
`

const StyledText = styled(Text)`
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;

  ${({theme}) => theme.below.md} {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledLargeText = styled(LargeText)`
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;

  ${({theme}) => theme.below.md} {
    margin-left: auto;
    margin-tight: auto;
  }
`

const Links = styled(LinkContainer)`

  ${({theme}) => theme.above.md} {
    margin-top: 30px;
  }

  ${({theme}) => theme.above.lg} {
    margin-top: 60px;
  }
`

/*==============================================================================
  # Component
==============================================================================*/

class SectionTextImage extends Component {
  
  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }

  render () {

    let { id, isH1, margin, title, title_size, text, largetext, background, image, image_alignment, image_type, linktext, link } = this.props
    const Title = variableHeadingTag(getSize(title_size))
    const size = isH1 ? "h1" : "h2"
    const TextComponent = largetext ? StyledLargeText : StyledText
    let links = []
    links.push({
      link: link,
      colored: background !== 'blue'
    })

    const aspectRatio = image && image.localFile ? image.localFile.childImageSharp.aspectRatio : 1.55
    let justify = 'center'
    if ( image_type !== 'image' ) {
      justify = image_alignment === 'left' ? 'flex-start' : 'flex-end'
    }

    const useOriginalImageStyle = image_type === 'image' || (typeof window !== 'undefined' && window.matchMedia('(max-width: 991px)').matches)

    const originalImageStyle = {
      position: 'relative',
      width: '100%',
      paddingBottom: `${100/aspectRatio}%`
    }

    const coverImageStyle = {
      position: 'relative',
      width: '100%',
      height: '100%'
    }

    return (
      <Container id={id} ref={this.scrollRef} marginTop={margin}>
          <StyledRow background={background} justify={justify}>

            <Col col={12} md={image_type === 'image' ? 10 : 11}>
              <Row>

                <StyledCol col={12} md={6} order={image_alignment === 'left' ? 1 : 2}>
                  {image && image.localFile && image.localFile.childImageSharp &&
                    <ImgContainer extraPadding={background !== 'none'} imageType={image_type}>
                      <Img 
                        fluid={image.localFile.childImageSharp.fluid}
                        style={useOriginalImageStyle ? originalImageStyle : coverImageStyle}
                      />
                    </ImgContainer>
                  }
                </StyledCol>

                <ContentCol col={12} md={6} extraPadding={background !== 'none'} order={image_alignment === 'right' ? 1 : 2}>
                  <Title size={size}>{title}</Title>
                  <TextComponent small content={text}/>
                  {link && <Links linkText={linktext} links={links} />}
                </ContentCol>

              </Row>
            </Col>

          </StyledRow> 
      </Container>
    )
  }
}

export default SectionTextImage