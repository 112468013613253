import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { SubHeading } from '../../UI/Headings'
import Button, { buttonStyle } from '../../UI/Button'


export const FormSectionHeading = styled(SubHeading)`
	margin-top: 40px;
	color: ${({theme}) => theme.colors.white};
	font-weight: 400;
`

export const SubmitMessage = styled('div')`
	position: relative;
	width: 100%;
	padding: 15px 30px 15px 45px;
	margin-top: 30px;
	color: ${({theme}) => theme.colors.black};
	background-color: ${({theme}) => theme.colors.white};
	border-radius: 30px;
	box-shadow: ${({theme}) => theme.styles.boxshadow};
	overflow: hidden;

	&::after {
		display: block;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 100%;
		background-color: ${({theme, error}) => error ? theme.colors.error : theme.colors.success};
	}
`

export const Message = styled('span')`
	display: block;
	position: absolute;
	left: 15px;
	bottom: 5px;
	font-size: 10px;
`

export const SuccessMessage = styled(Message)`
	color: ${({theme}) => theme.colors.success};
`

export const ErrorMessage = styled(Message)`
	color: ${({theme}) => theme.colors.error};
`

export const Label = styled('label')`
	padding-left: 15px;
	color: ${({theme}) => theme.colors.white};
	${({theme}) => theme.fontSizes.description}
	font-weight: 700;
`

export const Tag = styled('div')`
	position: relative;
	${({theme}) => buttonStyle({theme: theme, secondary: true})}
	margin: 5px;
	padding-left: 48px;
	font-weight: 400;
	user-select: none;

	&:hover {
		.checkbox {
			border-color: ${({theme}) => theme.colors.white};
		}
	}

	.checkbox {
		display: block;
		position: absolute;
		top: 50%;
		left: 20px;
		width: 20px;
		height: 20px;
		border: 1px solid ${({theme}) => theme.colors.grey};
		transition: all 250ms ${({theme}) => theme.easings.secondary};
		transform: translateY(-50%);

		&:before {
			display: block;
			content: "";
			position: absolute;
			bottom: 45%;
			left: 20%;
			width: 30%;
			height: 60%;
			max-width: 0%;
			max-height: 0%;
			opacity: 0;
			border-width: 0px 2px 2px 0px;
			border-style: solid;
			border-color: ${({theme}) => theme.colors.white};
			transform-origin: left bottom;
			transform: rotate( 45deg );
			transition: all 0ms ease;
		}
	}

	${({theme, choosen}) => choosen && css`
		.checkbox {
			border-color: ${theme.colors.white};
			background-color: ${theme.colors.primary};

			&::before {
				max-width: 50%;
				max-height: 110%;
				opacity: 1;
				transition: opacity 0ms linear 0ms,
								max-width 35ms ease-in 0ms,
								max-height 35ms ease-out 35ms;
			}
		}
	`}
`

export const InputContainer = styled('div')`
	position: relative;
	padding-bottom: 20px;
	color: ${({theme}) => theme.colors.white};
`

const InputStyle = ({ theme, isValid }) => css`
	display: block;
	width: 100%;
	padding: 10px 15px;
	margin: 0px;
	border-radius: 30px;
	background-color: transparent;
	border: 2px solid ${theme.colors.white};
	color: ${theme.colors.white};
	outline: none;

	${!isValid && css`
		border-color: ${theme.colors.error};
	`}
`

export const Input = styled('input')`
	${InputStyle}
`

export const Textarea = styled('textarea')`
	${InputStyle}
	height: 150px;
`

export const SubmitButton = styled(Button)`
	margin-top: 20px;
`