import React from 'react'
import styled from '@emotion/styled'

/*==============================================================================
  # Styles
==============================================================================*/

const Checkbox = styled('label')`
	position: relative;
	display: block;
	min-height: 20px;
	padding-left: 30px;
	overflow: hidden;
	font-size: 11px;
	font-style: italic;
	letter-spacing: 0.55px;
	color: ${({theme}) => theme.colors.white};
	cursor: pointer;
	
	.text {
		user-select: none;
	}
	
	//Hide standard checkbox
	input[type="checkbox"] {
		display:none;
	}

	//Style new checkbox
	.checkbox {
		display: block;
		position: absolute;
		top: 50%;
		left: 0px;
		width: 20px;
		height: 20px;
		background-color: ${({theme}) => theme.colors.white};
		transform: translateY(-50%);
		
		&:before {
			display: block;
			content: "";
			position: absolute;
			bottom: 45%;
			left: 20%;
			width: 30%;
			height: 60%;
			max-width: 0%;
			max-height: 0%;
			opacity: 0;
			border-width: 0px 2px 2px 0px;
			border-style: solid;
			border-color: ${({theme}) => theme.colors.primary};
			transform-origin: left bottom;
			transform: rotate( 45deg );
			transition: all 0ms ease;
		}
	}

	//Marking css for new checkbox
	input[type="checkbox"]:checked ~ .checkbox:before {
		max-width: 50%;
		max-height: 110%;
		opacity: 1;
		transition: opacity 0ms linear 0ms,
						max-width 35ms ease-in 0ms,
						max-height 35ms ease-out 35ms;
	}
`

/*==============================================================================
  # Component
==============================================================================*/

const CheckboxComponent = ({name, label, className, ...rest}) => {
	const id = name+'-'+parseInt(Math.random()*1000)
	return (
		<Checkbox htmlFor={id} className={className}>
		  <input type="checkbox" id={id} name={name} {...rest} />
		  <span className="checkbox"></span>
			<span className="text">{label}</span>
		</Checkbox>
	)
}

export default CheckboxComponent