import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Container, { Row, Col } from '../UI/Grid'
import { Mega } from '../UI/Headings'
import Text, { LargeText } from '../UI/Text'
import ContactCTA from '../UI/ContactCTA'
import { getGlobal, setGlobal } from '../../utility/functions'

/*==============================================================================
  # Styles
==============================================================================*/

const FullContainer = styled(Container)`
  min-height: 60vh;
  padding: 60px 0px;
  text-align: center;
  color: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.above.md} {
    padding: 100px 0px;
  }

  ${({theme}) => theme.above.lg} {
    padding: 150px 0px;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 1;
  }
`

const ContentContainer = styled(Container)`
  justify-content: center;
  z-index: 2;
`

const StyledText = styled(Text)`
  margin: 15px auto;
  color: ${({theme}) => theme.colors.white};
`

const StyledLargeText = styled(LargeText)`
  margin: 15px auto;
  color: ${({theme}) => theme.colors.white};
`

const ContactContainer = styled(ContentContainer)`
  margin-top: -30px;

  ${({theme}) => theme.above.sm} {
    margin-top: -55px;
  }
`

const IntroContactCTA = styled(ContactCTA)`
  width: 100%;
  max-width: 800px;
  justify-content: space-around;
  margin: 0 auto;
  padding: 15px;
  background-color: ${({theme}) => theme.colors.primary};


  ${({theme}) => theme.below.sm} {
    flex-direction: column;

    .icon-box {
      margin-left: -30px;
    }
  }

  ${({theme}) => theme.above.sm} {
    padding: 30px;
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionIntro extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }
  
  render () {

    let { id, isH1, margin, image, title, text, largetext, contactinfo } = this.props

    const size = isH1 ? "h1" : "h2"
    const TextComponent = largetext ? StyledLargeText : StyledText


    return (
      <Fragment>
        <FullContainer id={id} ref={this.scrollRef} fullWidth marginTop={margin}>

          {image && image.localFile &&
            <Img 
              fluid={image.localFile.childImageSharp.fluid}
              alt={image.alt_text}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }}
            />
          }

          <ContentContainer>
            <Row>
              <Col col={12}>
                <Mega size={size}>{title}</Mega>
                <TextComponent content={text} />
              </Col>
            </Row>
          </ContentContainer>

        </FullContainer>

        {contactinfo && <ContactContainer>
          <IntroContactCTA white={true} />
        </ContactContainer>}

      </Fragment>
    )
  }
}


export default SectionIntro