
const formatMessage = fields => {

	fields = fields.filter(field => field.name !== 'acceptence' && field.value !== undefined)

	return `
		<main>
			<h1>Kontaktformulär</h1>
			<table>
				<tbody>
					${fields && fields.map(field => `
						<tr>
							<td style="padding-right: 5px;">${field.label}:</td>
							<td>${field.value}</td>
						</tr>
					`).join('')}
				</tbody>
			</table>
			<hr style="margin: 50px 0px 15px;">
			<p>Meddelandet skickades från kontaktformuläret på sidan ${window.location.href}</p>
		</main>
	`
}

export default formatMessage