import React, { Component } from 'react'
import styled from '@emotion/styled'
import { InputContainer, Label, Tag } from './InputStyles.js'

/*==============================================================================
  # Styles
==============================================================================*/

const Fieldset = styled('fieldset')`
	margin: -5px;
	margin-top: 0px;
`

/*==============================================================================
  # Component
==============================================================================*/

class TagOption extends Component {

	componentDidMount() {
		const { choosen } = this.props 
		if ( choosen ) {
			this.setState({
				choosen: true
			})
		}
	}

	state = {
		choosen: false
	}

	handleClick = () => {
		const { value } = this.props
		const { choosen } = this.state
		this.setState({
			choosen: !choosen
		})

		if ( this.props.onClick ) {
			this.props.onClick({
				value: value,
				choosen: !choosen
			})
		}
	}

	render() {

		let { value } = this.props

		return (
			<Tag 
				title={value}
				choosen={this.state.choosen}
				onClick={this.handleClick}
			>
				<span className="checkbox"></span>
				{value}
			</Tag>
		)
	}
}

class Tags extends Component {

	componentDidMount() {
		let valuesArray = []
		const { options } = this.props

		if ( options ) {
			valuesArray = options.filter(option => option.choosen).map(option => option.value)
			valuesArray = valuesArray.filter(item => !!item)

			this.setState({
				value: valuesArray.join(', ')
			})
		}
	}

	state = {
		value: ""
	}

	onClick = data => {
		let valuesArray = this.state.value.split(', ')

		//Add/Remove value
		if ( data.choosen ) {
			valuesArray.push(data.value)
		} else {
			valuesArray = valuesArray.filter(item => item !== data.value)
		}

		//Clear array from empty values
		valuesArray = valuesArray.filter(item => !!item)

		this.setState({
			value: valuesArray.join(', ')
		})
	}

	render() {
		const { label, name, options } = this.props

		return (
			<InputContainer>
				{label && <Label>{label}</Label>}
				<input type="hidden" name={name} value={this.state.value} />
				<Fieldset>
					{options && options.map((option, i) => (
						<TagOption 
							key={i}
							onClick={data => this.onClick(data)}
							{...option}
						/>
					))}
				</Fieldset>
			</InputContainer>
		)
	}
}

export default Tags