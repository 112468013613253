import React, { Component } from 'react'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import axios from 'axios'
import { ValidatorForm } from 'react-form-validator-core'
import InputField from './Inputs/InputField'
import Textarea from './Inputs/Textarea'
import Checkbox from './Inputs/Checkbox'
import Tags from './Inputs/Tags'
import formatMessage from './formatMessage'
import { Heading } from '../UI/Headings'
import Text from '../UI/Text'
import { FormSectionHeading, SubmitMessage, SubmitButton } from './Inputs/InputStyles'


/*==============================================================================
  # Styles
==============================================================================*/

const IntroText = styled('div')`
  margin-bottom: 40px;
`

const StyledHeading = styled(Heading)`
  text-transform: none;
  color: ${({theme}) => theme.colors.white};
`

const StyledText = styled(Text)`
  color: ${({theme}) => theme.colors.white};
`

/*==============================================================================
  # Component
==============================================================================*/


const validatorMessages = {
  required: {
    validator: 'required',
    message: 'Fältet är obligatorisk'
  },
  email: {
    validator: 'isEmail',
    message: 'Fältet verkar inte innehålla en e-postadress'
  },
  tel: {
    validator: 'matchRegexp:^[0-9 ()+-].*$',
    message: 'Fältet verkar inte innehålla ett telefonnummer'
  } 
}


class FormComponent extends Component {

  componentDidMount() {
    let fields = []
    let baseData = []
    const componentFields = this.props.fields

    fields = componentFields.map((field, i) => {
      let name = field.name
      name = !name && field.label ?  _.kebabCase(field.label) : name
      name = !name && field.acf_fc_layout ?  _.kebabCase(field.acf_fc_layout)+`-${i}` : name
      baseData.push({
        label: field.label || name,
        name: name
      })
      return {
        name: name,
        layout: field.acf_fc_layout,
        ...field
      }
    })

    this.setState({
      fields: fields,
      baseData: baseData
    })
  }

  state = {
    fields: [],
    baseData: [],
    loading: false,
    submitPossible: false,
    formMessage: {
      error: false,
      message: ""
    }
  }

  handleCheck = event => {
    this.setState({
      submitPossible: event.target.checked
    })
  }

  onFailedValidation = () => {
    this.setState({
      formMessage: {
        error: true,
        message: "Ett eller flera fält innehåller fel. Kontrollera och försök igen."
      }
    })
  }

  handleSubmit = (event, wordpressUrl)  => {
    event.preventDefault()
    const { wordpressID } = this.props 
    const { submitPossible } = this.state 
    if ( !submitPossible ) {

      this.setState({
        formMessage: {
          error: true,
          message: "Ett eller flera fält innehåller fel. Kontrollera och försök igen."
        }
      })

    } else {

      /*
       * Prepare data
       */

      const formData = new FormData(event.target)
      let { baseData } = this.state
      let values = []
      let keys = []

      for (let key of formData.keys()) {
        keys.push(key)
      }

      for (let value of formData.values()) {
        values.push(value)
      }

      keys.forEach((key, i) => {
        baseData = baseData.map(data => {
          if ( data.name === key ) {
            data.value = values[i]
          }
          return data
        }) 
      })

      /*
       * Send data
       */

      this.setState({
        loading: true,
        submitPossible: false
      }, () => {
        axios({
          method: 'post',
          url: `${wordpressUrl}/wp-json/core/form`,
          data: {
            wordpressID: wordpressID,
            message: formatMessage(baseData),
          }
        })
        .then(response => {

          const formMessage = response.status === 200 ? "Tack, ditt meddelande har skickats." : "Serverfel, meddelandet kunde inte skickas."

          this.setState({
            loading: false,
            submitPossible: response.status !== 200,
            formMessage: {
              error: response.status !== 200,
              message: formMessage
            }
          })
        })
        .catch(error => {
          console.log('Form error local', error)
          this.setState({
            loading: false,
            submitPossible: true,
            formMessage: {
              error: true,
              message: "Någonting gick fel, meddelandet kunde inte skickas."
            }
          })
        })
      })
    }
  }

  render () {

    let { title, text, acceptenceText, submitText } = this.props
    const { fields, loading, formMessage, submitPossible } = this.state

    submitText = submitText ? submitText : "Skicka"
    acceptenceText = acceptenceText ? acceptenceText : "Genom att kryssa i den här rutan godkänner du att vi hanterar dina personuppgifter enligt vår GDPR-policy."

    return (
      <StaticQuery
        query={graphql`
          query WordpressUrlQuery {
            ...WordpressUrl
          }
        `}
        render={data => {

          const { wordpressUrl } = data.site.siteMetadata

          return (
            <ValidatorForm
              ref={r => (this.form = r)}
              onSubmit={event => this.handleSubmit(event, wordpressUrl)}
              onError={this.onFailedValidation}
              instantValidate={false}
            >

              {(title || text) && <IntroText>
                {title && <StyledHeading>{title}</StyledHeading>}
                {text && <StyledText content={text} />}
              </IntroText>}


              {fields && fields.map((field, i) => {

                const { layout, required } = field

                let validators = []
                let errorMessages = []

                if ( required ) {
                  validators.push(validatorMessages.required.validator)
                  errorMessages.push(validatorMessages.required.message)
                }

                if ( layout in validatorMessages ) {
                  validators.push(validatorMessages[layout].validator)
                  errorMessages.push(validatorMessages[layout].message)
                }

                /*
                 * Title
                 */
                if ( layout === 'title' ) {
                  return (
                    <FormSectionHeading key={i}>{field.label}</FormSectionHeading>
                  )
                }

                /*
                 * Tags
                 */
                if ( layout === 'tags' ) {
                  return (
                    <Tags key={i} {...field} />
                  )
                }

                /*
                 * Textarea
                 */
                if ( layout === 'textarea' ) {
                  return (
                    <Textarea
                      key={i}
                      label={field.label}
                      name={field.name}
                      validators={validators}
                      errorMessages={errorMessages}
                    />
                  )
                }

                /*
                 * Basic Input
                 */
                return (
                  <InputField
                    key={i}
                    label={field.label}
                    name={field.name}
                    validators={validators}
                    errorMessages={errorMessages}
                  />
                )
              })}

              <div>
                <Checkbox name="acceptence" label={acceptenceText} onChange={this.handleCheck}/>
              </div>

              <SubmitButton 
                type="submit" 
                secondary 
                disabled={!submitPossible}
                loading={loading}
                loadingText="Skickar..."
              >
                {submitText}
              </SubmitButton>

              {formMessage.message && <div>
                <SubmitMessage error={formMessage.error}>
                  {formMessage.message}
                </SubmitMessage>
              </div>}

            </ValidatorForm>
          )
        }}
      />
    )
  }
}

export default FormComponent
