import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Helmet } from 'react-helmet'
import Container, { Row, Col } from '../UI/Grid'
import { variableHeadingTag, getSize } from '../UI/Headings'
import { getGlobal, setGlobal } from '../../utility/functions'
import Link from '../Link'


/*==============================================================================
  # Styles
==============================================================================*/

const TitleRow = styled(Row)`
  text-align: center;
  margin-bottom: 30px;

  ${({theme}) => theme.above.md} {
    margin-bottom: 60px;
  }
`




/* const Iframe = styled('iframe')`
  width: 100%; 
  border: 0px;
  overflow: hidden;

  ${({heightValues}) => Object.keys(heightValues).map(breakpoint => css`
    @media (min-width: ${breakpoint}px) {
      height: ${heightValues[breakpoint]}px;
    }
  `)}
` */

/* const ListingLink = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
 */


/*==============================================================================
  # Component
==============================================================================*/

class SectionCars extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }


componentWillUnmount (){
  const placeholder = document.getElementById('script-placeholder')
  placeholder.innerHTML = ''
  if(window !== 'undefined'){
      window.location.reload();
  }
 }



  componentDidMount(prevProps) {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }

    this.setState({
      showIframe: true
    })


    let bytbildAccess = {
      new: {
        uniquescript: `window.postId = 9`,
        uniqueid: `9`
      },
      stock: {
        uniquescript: `window.postId = 3`,
        uniqueid: `3`
      }
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = bytbildAccess[this.props.iframe_type].uniquescript

    const scriptglobal = document.createElement('script')
    scriptglobal.type = 'text/javascript'
    scriptglobal.innerHTML = `
    var baseUrl = "https://eksjobilaffar.accesspaket.bytbilcms.com/";
    var restUrl = baseUrl + "wp-json/accesspackage/v1";
    var buildUrl = baseUrl + "app/mu-plugins/triggerfish-bytbil-accesspaket/frontend/build/";
    var getJSON = function(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.onload = function() {
        if (xhr.status !== 200) return false;
        callback(JSON.parse(xhr.response));
      };
      xhr.send();
    };
    
    getJSON(buildUrl + "asset-manifest.json?siteUrl=" + restUrl, function(json) {
      for (var fileIndex in json.entrypoints) {
        if (!json.entrypoints[fileIndex]) {
        continue;
        }
    
        var file = json.entrypoints[fileIndex];
        var tag;
          if (file.indexOf(".css") !== -1) {
            tag = document.createElement("link");
            tag.rel = "stylesheet";
            tag.type = "text/css";
            tag.href = buildUrl + file;
          } else {
            tag = document.createElement("script");
            tag.src = buildUrl + file;
            tag.type = "text/javascript";
          }
        document.head.appendChild(tag);
      }
    });

    `
    //this.props.bytbildAccess[this.props.iframe_type].uniquescript

    const placeholder = document.getElementById('script-placeholder')
    placeholder.appendChild(script)
    placeholder.appendChild(scriptglobal)
  }

  state = {
    showIframe: false
  }


  render () {

    console.log(this.props);
    let { id, isH1, margin, title, title_size, iframe_type, listning_link, location } = this.props
    const Title = variableHeadingTag(getSize(title_size))
    const size = isH1 ? "h1" : "h2"

    const protocol = typeof window !== 'undefined' ? window.location.protocol : 'https:'
    const canonical = typeof window !== 'undefined' ? window.location.href : 'https://eksjobilaffar.se/'


    /*
    POST-ID:n:
      Alla fordon i lager: 3
      Nya fordon i lager: 5
      Begagnade fordon i lager: 7
      Senast inkomna: 9
    */

/*     let iframeSrc = {
      new: {
        height: {
          0:  1555,
          798: 380
        },
        src: `//access.bytbil.com/eksjobilaffarab2016/Access/Home/Senaste/AESDUDIAIYAWDnkAAYsA!?parentUrl=${canonical}`,
        //src: `//access.bytbil.com/eksjobilaffarab2016/Access/Home/Senaste?parentUrl=${canonical}`,
        script: `<script src="${protocol}//access.bytbil.com/eksjobilaffarab2016/access/content/getcontent/1/accesspaket-autoloader.min.js" data-packagename="eksjobilaffarab2016" data-assortment="AESDUDIAIYAWDq1YADDg!" data-actionname="Senaste" data-idname="objekt"></script>`
      },
      stock: {
        height: {
          0: 9000,
          531: 5000,
          798: 2600
        },
        src: `//access.bytbil.com/eksjobilaffarab2016/Access/Home/SokLista/AESDUDIAIYAWDnkAAYsA!?parentUrl=${canonical}`,
        //src: `//access.bytbil.com/eksjobilaffarab2016/Access/Home/SokLista?parentUrl=${canonical}`,
        script: `<script src="${protocol}//access.bytbil.com/eksjobilaffarab2016/access/content/getcontent/1/accesspaket-autoloader.min.js" data-packagename="eksjobilaffarab2016" data-assortment="AESDUDIAIYAWDq1YADFg!" data-actionname="SokLista" data-idname="objekt"></script>`
      }
    } */




    return (
      <Container id={id} ref={this.scrollRef} marginTop={margin}>

        <Helmet

/>








        {title && <TitleRow>
          <Col col={12}>
            <Title size={size}>{title}</Title>
          </Col>
        </TitleRow>}

        <Row>
          <Col col={12}>
            <div id="script-placeholder"/><div id="tfap-root" className="tfap-app"/>
          </Col>
        </Row>
      </Container>
    )

    
/*     return (
      <Container id={id} ref={this.scrollRef} marginTop={margin}>

        <Helmet>
          {iframeSrc[iframe_type].script}
        </Helmet>

        {title && <TitleRow>
          <Col col={12}>
            <Title size={size}>{title}</Title>
          </Col>
        </TitleRow>}

        <Row>
          <Col col={12}>
            {listning_link && <ListingLink to={listning_link} />}
            {this.state.showIframe && <Iframe 
              heightValues={iframeSrc[iframe_type].height} 
              src={iframeSrc[iframe_type].src} 
            />}
          </Col>
        </Row>
      </Container>
    ) */
    
  }
}

export default SectionCars