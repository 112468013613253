import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Container, { Row, Col } from '../UI/Grid'
import { variableHeadingTag, getSize } from '../UI/Headings'
import TextCard, { TextCardContainer } from '../UI/TextCard'
import { getGlobal, setGlobal } from '../../utility/functions'


/*==============================================================================
  # Styles
==============================================================================*/

const StyledContainer = styled(Container)`
  z-index: 3;

  ${({theme, marginTop}) => marginTop === 'negative' ? css`
    margin-top: -45px;

    ${theme.above.md} {
      margin-top: -60px;
    }

    ${theme.above.lg} {
      margin-top: -80px;
    }
  ` : null}
`

const TitleCol = styled(Col)`
  margin-bottom: 30px;
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionTextCards extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }
  
  render () {

    let { id, isH1, title, title_size, margin, cards } = this.props

    const Title = variableHeadingTag(getSize(title_size))
    const size = isH1 ? "h1" : null

    return cards && Array.isArray(cards) ? (
      <StyledContainer id={id} ref={this.scrollRef} marginTop={margin}>
        <Row>

          {title && <TitleCol col={12}>
            <Title size={size}>{title}</Title>
          </TitleCol>}

          <Col col={12}>
            <TextCardContainer>
              {cards.map((card, i) => <TextCard key={i} {...card} link={{to: card.link.url, ...card.link}} />)}
            </TextCardContainer>
          </Col>

        </Row>
      </StyledContainer>
    ) : null
  }
}

export default SectionTextCards