import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import SectionCars from './SectionCars'
import SectionCoworkers from './SectionCoworkers'
import SectionFormImage from './SectionFormImage'
import SectionFormMap from './SectionFormMap'
import SectionIntro from './SectionIntro'
import SectionIntroBtns from './SectionIntroBtns'
import SectionText from './SectionText'
import SectionTextBackground from './SectionTextBackground'
import SectionTextCards from './SectionTextCards'
import SectionTextImage from './SectionTextImage'
import SectionTextStackedCards from './SectionTextStackedCards'


/*==============================================================================
  # Component
==============================================================================*/


export const PrepareProvider = sections => {
  if ( sections && Array.isArray(sections) ) {
    sections.map(section => section.sectionKey = section.__typename.replace('WordPressAcf_','') )
  }
  return sections
}


class Provider extends Component {

  state = {
    templates: {
      SectionCars,
      SectionCoworkers,
      SectionFormImage,
      SectionFormMap,
      SectionIntro,
      SectionIntroBtns,
      SectionText,
      SectionTextBackground,
      SectionTextImage,
      SectionTextStackedCards,
      SectionTextCards
    }
  }

  getTemplate = name => {
    return this.state.templates[name]
  }

  render () {
    const { sections } = this.props
    let usedH1 = false

    return (
      <Fragment>
        {sections && sections.length > 0 ? sections.map((section, i) => {

          const Section = this.getTemplate(section.sectionKey)
          const { title, anchorID } = section
          const id = anchorID ? _.kebabCase(anchorID) : null
          const isH1 = !!title && !usedH1 ? true : false;
          usedH1 = !!title ? true : usedH1

          return (
            <Section id={id} key={i} index={i} isH1={isH1} {...section} />
          )
        }) : null}
      </Fragment>
    )
  }
}

export default Provider
