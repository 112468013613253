import React, { Component } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Form from '../Form/Form'
import Container, { Row, Col } from '../UI/Grid'
import { getGlobal, setGlobal } from '../../utility/functions'

/*==============================================================================
  # Styles
==============================================================================*/

const StyledCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  min-height: 500px;
`

const FromContainer = styled('div')`
  display: flex;
  align-items: center;
  order: 2;
  width: 100%;
  padding: 30px 15px;
  background-color: ${({theme}) => theme.colors.blue};

  ${({theme}) => theme.above.md} {
    order: 1;
    width: 50%;
    max-width: 600px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  ${({theme}) => theme.above.lg} {
    padding: 60px;
  }

  ${({theme}) => theme.above.xl} {
    padding: 80px 8.33333%;
  }

  form {
    margin-left: auto;
    margin-right: auto;
  }
`

const ImageContainer = styled('div')`
  position: relative;
  order: 1;
  width: 100%;
  padding-bottom: 66.66%;
  background-color: ${({theme}) => theme.colors.grey};

  ${({theme}) => theme.above.md} {
    flex-grow: 1;
    order: 2;
    width: auto;
    height: 100%;
    padding-bottom: 0%;
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionFormImage extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }
  
  render () {

    let { id, title, text, margin, image, form } = this.props
    const wordpressID = form[0].wordpress_id
    form = form[0].acf.form

    return (
      <Container id={id} ref={this.scrollRef} marginTop={margin}>
        <Row>
          <StyledCol col={12}>
            
            <FromContainer>
              <Form 
                title={title} 
                text={text}
                wordpressID={wordpressID}
                fields={form.wordpress_fields}
                acceptenceText={form.acceptence_text}
                submitText={form.submit_text}
              />
            </FromContainer>

            <ImageContainer>      
              {image && image.localFile &&
                <Img 
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={image.alt_text}
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                  }}
                />
              }
            </ImageContainer>

          </StyledCol>
        </Row>
      </Container>
    )
  }
}


export default SectionFormImage