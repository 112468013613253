import React, { Component, Fragment } from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import { Input, Label, InputContainer, ErrorMessage } from './InputStyles.js'


/*==============================================================================
  # Component
==============================================================================*/

class InputValidator extends ValidatorComponent {
  
  errorText = () => {
    const { isValid } = this.state
    return !isValid ? <ErrorMessage>{this.getErrorMessage()}</ErrorMessage> : null
  }

  render() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
    const { isValid } = this.state

    return (
      <Fragment>
        <Input
          {...rest}
          isValid={isValid}
          ref={(r) => { this.input = r }}
        />
        {this.errorText()}
      </Fragment>
    )
  }
}

class InputField extends Component {
 	
  componentDidMount() {
    const { value } = this.props
    if ( value) {
      this.setState({
        value: value
      })
    }
  }

  state = {
    value: ""
  }

  handleChange = event => {
    if ( event.target ) { 
      const { value } = event.target
      this.setState({
        value: value ? value : ""
      })
    }
  }

  render() {
    const { label, validators, ...rest } = this.props;
    const isRequired = validators.includes('required')
    const { value } = this.state

    return (
      <InputContainer>
      	{label && <Label>{label}{isRequired && "*"}</Label>}
        <InputValidator 
          {...rest}
          type="text"
          value={value}
          validators={validators}
          onChange={this.handleChange}
        />
      </InputContainer>
    )
  }
}
 
export default InputField