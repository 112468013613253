import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import { Textarea, Label, InputContainer, ErrorMessage } from './InputStyles.js'


/*==============================================================================
  # Component
==============================================================================*/

class TextareaField extends ValidatorComponent {
 	
 	errorText() {
	  const { isValid } = this.state
	  return !isValid ? <ErrorMessage>{this.getErrorMessage()}</ErrorMessage> : null
  }

  render() {
    const { label, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
    const isRequired = validators.includes('required')
    const { isValid } = this.state

    return (
      <InputContainer>
        {label && <Label>{label}{isRequired && "*"}</Label>}
        <Textarea
          {...rest}
          isValid={isValid}
          ref={(r) => { this.input = r }}
        />
        {this.errorText()}
      </InputContainer>
    )
  }
}
 
export default TextareaField