import React, { Component } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Container, { Row, Col } from '../UI/Grid'
import { variableHeadingTag, getSize } from '../UI/Headings'
import Text, { LargeText } from '../UI/Text'
import LinkContainer from '../UI/LinkContainer'
import { getGlobal, setGlobal } from '../../utility/functions'

/*==============================================================================
  # Styles
==============================================================================*/

const ContentContainer = styled(Container)`
  text-align: center;
  color: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.above.md} {
    text-align: ${({alignmnet}) => alignmnet};
  }
`

const ContentRow = styled(Row)`
  position: relative;
  min-height: 60vh;
  padding-top: 60px;
  padding-bottom: 60px;

  ${({theme}) => theme.above.md} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${({theme}) => theme.above.lg} {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    background-color: rgba(0,0,0,0.7);
    z-index: 1;
  }
`

const ContentCol = styled(Col)`
  z-index: 2;
  padding: 0px 30px;
`


const StyledText = styled(Text)`
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${({theme}) => theme.colors.white};
`

const StyledLargeText = styled(LargeText)`
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${({theme}) => theme.colors.white};
`


const Links = styled(LinkContainer)`
  margin-top: 30px;

  ${({theme}) => theme.above.md} {
    margin-top: 60px;
  }

  ${({theme}) => theme.above.lg} {
    margin-top: 90px;
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionTextBackground extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }
  
  render () {

    let { id, isH1, margin, image, title, title_size, text, content_alignment, largetext, linktext, link } = this.props
    const Title = variableHeadingTag(getSize(title_size))
    const size = isH1 ? "h1" : "h2"
    const TextComponent = largetext ? StyledLargeText : StyledText
    let links = []
    links.push({
      link: link
    })
 
    return (
      <ContentContainer id={id} ref={this.scrollRef} alignmnet={content_alignment} marginTop={margin}>
        <ContentRow>

          {image && image.localFile &&
            <Img 
              fluid={image.localFile.childImageSharp.fluid}
              alt={image.alt_text}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 15,
                right: 15
              }}
            />
          }

          <ContentCol col={12} md={10}>
            <Title size={size}>{title}</Title>
            <TextComponent small content={text} />
            {link && <Links linkText={linktext} links={links} />}
          </ContentCol>
        </ContentRow>
      </ContentContainer>
    )
  }
}

export default SectionTextBackground