import React, { Component } from 'react'
import styled from '@emotion/styled'
import Container, { Row, Col } from '../UI/Grid'
import { variableHeadingTag, getSize } from '../UI/Headings'
import CoworkerCard, { CoworkerCardContainer } from '../UI/CoworkerCard'
import { getGlobal, setGlobal } from '../../utility/functions'


/*==============================================================================
  # Styles
==============================================================================*/

const TitleRow = styled(Row)`
  text-align: center;
  margin-bottom: 30px;

  ${({theme}) => theme.above.md} {
    margin-bottom: 60px;
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionCoworkers extends Component {
  
  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }

  render () {

    let { id, isH1, title, title_size, margin, coworkers } = this.props
    const Title = variableHeadingTag(getSize(title_size))
    const size = isH1 ? "h1" : "h2"

    return (
      <Container id={id} ref={this.scrollRef} marginTop={margin}>
        {title && <TitleRow>
          <Col col={12}>
            <Title size={size}>{title}</Title>
          </Col>
        </TitleRow>}
        
        <Row>
          <Col col={12}>
            <CoworkerCardContainer>
              {coworkers && coworkers.map((card, i) => card.coworker[0] ? <CoworkerCard key={i} name={card.coworker[0].post_title} {...card.coworker[0].acf} /> : null)}
            </CoworkerCardContainer>
          </Col>
        </Row>

      </Container>
    )
  }
}

export default SectionCoworkers