import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Container, { Row, Col } from '../UI/Grid'
import { variableHeadingTag, getSize } from '../UI/Headings'
import Text, { LargeText } from '../UI/Text'
import LinkContainer from '../UI/LinkContainer'
import TextCard, { TextCardContainer } from '../UI/TextCard'
import { getGlobal, setGlobal } from '../../utility/functions'

/*==============================================================================
  # Styles
==============================================================================*/

const StyledRow = styled(Row)`
  position: relative;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  ${({theme}) => theme.below.xl} {
    text-align: center;
  }

  ${({theme}) => theme.above.xl} {
    order: ${({order}) => order};
  }
`

const ContentCol = styled(StyledCol)`
  padding: 30px;

  ${({theme}) => theme.above.xl} {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 15px;
    padding-right: 15px;

    ${({order}) => order === 1 ? css`
      padding-right: 60px;
    ` : css`
      padding-left: 60px;
    `}
  }
`

const StyledText = styled(Text)`
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;

  ${({theme}) => theme.below.xl} {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledLargeText = styled(LargeText)`
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;

  ${({theme}) => theme.below.xl} {
    margin-left: auto;
    margin-tight: auto;
  }
`

const Links = styled(LinkContainer)`

  ${({theme}) => theme.above.xl} {
    margin-top: 60px;
  }
`

const StackedCardContainer = styled(TextCardContainer)`
  ${({theme}) => theme.above.xl} {
    justify-content: flex-start;
    margin: -15px;
    margin-bottom: ${({extraMarginNeeded}) => extraMarginNeeded ? '225px' : '0px'};
  }
`

const StackedTextCard = styled(TextCard)`
  ${({theme}) => theme.above.xl} {
    width: calc( 50% - 30px);

    &:nth-of-type(even) {
      transform: translateY(50%);
    }
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class SectionTextStackedCards extends Component {

  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props
    if ( id ) {
      let _global = getGlobal()
      _global.scrollToRefs[id] = this.scrollRef.current
      setGlobal(_global)
    }
  }

  render () {

    let { id, isH1, margin, title, title_size, text, largetext, linktext, link, cards } = this.props
    const Title = variableHeadingTag(getSize(title_size))
    const size = isH1 ? "h1" : "h2"
    const TextComponent = largetext ? StyledLargeText : StyledText
    let links = []
    links.push({
      link: link,
      colored: true
    })

    return (
      <Container id={id} ref={this.scrollRef} marginTop={margin}>
          <StyledRow>

            <ContentCol col={12} xl={6} order={2}>
              <Title size={size}>{title}</Title>
              <TextComponent small content={text}/>
              {link && <Links linkText={linktext} links={links} />}
            </ContentCol>

            <StyledCol col={12} xl={6} order={1}>
              {cards && Array.isArray(cards) && <StackedCardContainer extraMarginNeeded={cards.length%2 === 0}>
                {cards.map((card, i) => <StackedTextCard key={i} to={card.url} {...card} />)}
              </StackedCardContainer>}
            </StyledCol>

          </StyledRow> 
      </Container>
    )
  }
}

export default SectionTextStackedCards 